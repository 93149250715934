@font-face {
    font-family: 'sohne';
    src: local('sohne'),
    url("./fonts/sohne-buch-webfont.woff2") format('woff2'),
    url("./fonts/sohne-buch-webfont.woff") format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sohne';
    src: local('sohne'),
    url("./fonts/sohne-halbfett-webfont.woff2") format('woff2'),
    url("./fonts/sohne-halbfett-webfont.woff") format('woff');
    font-weight: bold;
    font-style: normal;
}
